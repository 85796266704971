<template>
  <!--begin::Quotation Listing-->
  <div class="costing-template">
    <PageHeaderCount
      moduleType="costing"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_costings"
      countkey="costing_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'costing-listing'"
      v-if="getPermission('costing:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('costing:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <template>
                          <v-list-item-title
                            v-text="item.text"
                          ></v-list-item-title>
                        </template>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_costings
                          }}</template>
                          <template v-else>{{
                            item.costing_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex quotation-listing-button-right"
              >
                <template v-if="getPermission('costing:create')">
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('costing.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                  <v-btn
                    v-if="false"
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button"
                    v-on:click="exportQuotation"
                    color="cyan white--text"
                  >
                    <v-icon dark left>mdi-database-export</v-icon> Export
                  </v-btn>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>

                <v-menu
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="quotation"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Costing"
          :basicSearchFields="[
            'barcode',
            'reference',
            'title',
            /*     'sales',
            'prepared_by', */
            /* 'attention', */
            'company_name',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="quotation-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data.barcode"></Barcode>
                          <p class="m-0 custom-nowrap-ellipsis">
                            <template v-if="data.tag_type">
                              <v-chip
                                label
                                small
                                class="text-capitalize"
                                text-color="white"
                                :color="
                                  data.tag_type == 'overseas' ? 'blue' : 'red'
                                "
                              >
                                {{ data.tag_type }}
                              </v-chip>
                            </template>
                          </p>
                          <div class="d-inline-grid">
                            <!--  <v-chip
                              label
                              color="blue darken-4"
                              class="text-white p-3 mb-1"
                              outlined
                              small
                              >{{ data.barcode }}
                            </v-chip> -->
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data?.costing_duplicate > 0"
                              outlined
                              small
                              ><!-- {{ data?.duplicate_relation?.barcode }} -->
                              Duplicate</v-chip
                            >
                            <v-chip
                              label
                              color="blue"
                              class="text-white p-3 mb-1"
                              v-if="data?.costing_revise > 0"
                              outlined
                              small
                              ><!-- {{ data?.revise_relation?.barcode }} -->
                              Revision</v-chip
                            >
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.q_requote_barcode"
                              outlined
                              small
                              >{{ data.q_requote_barcode }}</v-chip
                            >
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'quotation'">
                          <div>
                            <v-chip
                              label
                              v-if="data?.quotation_relation?.barcode"
                              :color="
                                getQuotationStatusColor(
                                  data?.quotation_relation?.status
                                )
                              "
                              class="p-3 mb-1"
                              text-color=""
                              outlined
                              small
                            >
                              {{ data?.quotation_relation?.barcode }}
                            </v-chip>
                            <em v-else class="text-muted"> no quotation</em>
                            <!--   <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.q_duplicate_barcode"
                              outlined
                              small
                              >{{ data.q_duplicate_barcode }}</v-chip
                            >
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.enquiry_barcode"
                              outlined
                              small
                              >{{ data.enquiry_barcode }}</v-chip
                            >
                            <v-chip
                              label
                              color="red"
                              class="text-white p-3 mb-1"
                              v-if="data.q_requote_barcode"
                              outlined
                              small
                              >{{ data.q_requote_barcode }}</v-chip
                            > -->
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'job_title'">
                          <div
                            class="quotation-listing-customer text-elipse"
                            style="width: 250px"
                          >
                            <p class="m-0 font-weight-bold text-capitalize">
                              {{ data.title }}
                            </p>
                            <!-- <template v-else
                              ><em class="text-muted"
                                >No title</em
                              ></template
                            > -->
                          </div>
                        </template>
                        <template
                          v-else-if="cols.field == 'opportunity_barcode'"
                        >
                          <div v-if="data?.project?.barcode">
                            <v-chip
                              label
                              v-if="data?.opportunity?.barcode"
                              color="red"
                              class="p-3 mb-1"
                              text-color=""
                              outlined
                              small
                            >
                              {{ data?.opportunity?.barcode }}
                            </v-chip>
                          </div>
                          <em v-else class="text-muted"> no opportunity </em>
                        </template>
                        <template v-else-if="cols.field == 'project_barcode'">
                          <div v-if="data?.project?.barcode">
                            <v-chip
                              label
                              v-if="data?.project?.barcode"
                              color="red"
                              class="p-3 mb-1"
                              text-color=""
                              outlined
                              small
                            >
                              {{ data?.project?.barcode }}
                            </v-chip>
                          </div>
                          <em v-else class="text-muted"> no project </em>
                        </template>
                        <template v-else-if="cols.field == 'basic_detail'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Sales representative: </b>
                              <template v-if="data.sales_user">
                                <v-chip
                                  v-if="
                                    data &&
                                    data.sales_user &&
                                    data.sales_user.display_name
                                  "
                                  small
                                  class="mb-1 mr-1"
                                >
                                  <v-avatar left small>
                                    <img
                                      v-if="
                                        data.sales_user &&
                                        data.sales_user.profile_logo &&
                                        data.sales_user.profile_logo.file
                                      "
                                      :src="
                                        data.sales_user.profile_logo.file.url
                                      "
                                    />
                                    <img
                                      v-else
                                      src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                    />
                                  </v-avatar>
                                  <span>{{
                                    data.sales_user.display_name
                                  }}</span>
                                </v-chip>
                              </template>
                              <template v-else
                                ><em class="text-muted"
                                  >No Sales representative</em
                                ></template
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Prepared by: </b>
                              <template
                                v-if="
                                  data.prepared_user &&
                                  data.prepared_user.display_name
                                "
                                >{{ data.prepared_by }}
                                <v-chip
                                  v-if="
                                    data &&
                                    data.prepared_user &&
                                    data.prepared_user.display_name
                                  "
                                  small
                                  class="mb-1 mr-1"
                                >
                                  <v-avatar left small>
                                    <img
                                      v-if="
                                        data.prepared_user &&
                                        data.prepared_user.profile_logo &&
                                        data.prepared_user.profile_logo.file
                                      "
                                      :src="
                                        data.prepared_user.profile_logo.file.url
                                      "
                                    />
                                    <img
                                      v-else
                                      src="https://ams-dev.businessthrust.com//media/misc/no_photo_found.png"
                                    />
                                  </v-avatar>
                                  <span>{{
                                    data.prepared_user.display_name
                                  }}</span>
                                </v-chip>
                              </template>
                              <template v-else
                                ><em class="text-muted"
                                  >No Prepared by</em
                                ></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'report'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis d-flex">
                              <template v-if="data.report"
                                ><span class="text-capitalize">{{
                                  data.report
                                }}</span></template
                              >
                              <template v-else
                                ><em class="text-muted">no report</em></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'rating'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis d-flex">
                              <template v-if="data.rating"
                                ><v-rating
                                  readonly
                                  dense
                                  v-model.trim="data.rating"
                                  background-color="orange lighten-3"
                                  color="orange"
                                ></v-rating
                              ></template>
                              <template v-else
                                ><em class="text-muted">No Rating</em></template
                              >
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_info'">
                          <div class="quotation-listing-customer">
                            <!--   <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              {{
                                data &&
                                data.customer &&
                                data.customer.display_name
                              }}
                            
                            </p> -->
                            <p class="m-0 custom-nowrap-ellipsis">
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.company_name
                                "
                              >
                                {{ data.customer.company_name }}
                              </template>
                              <em class="text-muted" v-else> no company</em>
                            </p>

                            <template
                              v-if="
                                false && data.customer.contact_person_count > 1
                              "
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.customer.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'company_detail'">
                          <div class="quotation-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="
                                  data.billing_person &&
                                  data.billing_person.primary_email
                                "
                              >
                                {{ data.billing_person.primary_email }}
                              </template>
                              <em class="text-muted" v-else> no email</em>
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="
                                  data.billing_person &&
                                  data.billing_person.primary_phone
                                "
                              >
                                {{ data.billing_person.primary_phone }}
                              </template>
                              <em class="text-muted" v-else>
                                no phone number</em
                              >
                            </p>
                            <template
                              v-if="
                                false && data.customer.contact_person_count > 1
                              "
                            >
                              <v-chip
                                small
                                color="blue white--text"
                                label
                                class="ml-2"
                              >
                                <span class="font-size-16 font-weight-500">
                                  +{{
                                    data.customer.contact_person_count - 1
                                  }}
                                  More</span
                                >
                              </v-chip>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'date'">
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.added_at"
                          >
                            <b>Created: </b> {{ formatDate(data.added_at) }}
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.validity_till && data.validity_till > 0"
                          >
                            <b>Validity: </b> {{ getValidityDate(data) }}({{
                              data.validity_till
                            }}Days)
                          </p>
                          <p
                            class="m-0 custom-nowrap-ellipsis"
                            v-if="data.sent_date"
                          >
                            <b>Sent: </b> {{ formatDate(data.sent_date) }}
                          </p>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="quotation-listing-status">
                            <CustomStatus
                              small
                              :status="data.status"
                              endpoint="costing/status"
                            ></CustomStatus>
                            <div class="mb-1">
                              <template
                                v-if="
                                  data.is_revise_rejected == 1 &&
                                  data.status == 8
                                "
                              >
                                <v-chip
                                  color="red"
                                  text-color="white"
                                  label
                                  small
                                  class="p-3 ml-2 mb-1"
                                >
                                  Rejected
                                </v-chip>
                              </template>
                            </div>
                            <div class="mb-1">
                              <template
                                v-if="
                                  data.pending_for_approver == 1 &&
                                  data.status == 1
                                "
                              >
                                <v-chip
                                  color="red"
                                  text-color="white"
                                  label
                                  small
                                  class="p-3 ml-2 mb-1"
                                >
                                  {{ data.pending_approver }}
                                </v-chip>
                              </template>
                            </div>
                            <div class="mb-1">
                              <v-chip
                                v-if="
                                  data &&
                                  data.quotation_duplicate &&
                                  data.quotation_duplicate > 0
                                "
                                label
                                small
                                color="red"
                                outlined
                                class="text-white p-3 ml-2 mb-1"
                                >Duplicate</v-chip
                              >
                              <v-chip
                                v-if="
                                  data &&
                                  data.quotation_requote &&
                                  data.quotation_requote > 0
                                "
                                label
                                small
                                color="red"
                                outlined
                                class="text-white p-3 ml-2"
                                >Re-Quote</v-chip
                              >
                              <div class="mb-1">
                                <template v-if="data.is_revised">
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        label
                                        color="red"
                                        outlined
                                        small
                                        class="text-white p-3 ml-2"
                                      >
                                        REVISED
                                      </v-chip>
                                    </template>
                                    <span>Quotation is Revised</span>
                                  </v-tooltip>
                                </template>
                              </div>
                              <div class="mb-1">
                                <template
                                  v-if="
                                    data &&
                                    data.quotation_revise &&
                                    data.quotation_revise > 0 &&
                                    !data.is_revised
                                  "
                                >
                                  <v-tooltip
                                    top
                                    content-class="custom-top-tooltip"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-chip
                                        v-bind="attrs"
                                        v-on="on"
                                        label
                                        color="teal"
                                        outlined
                                        small
                                        class="text-white p-3 ml-2"
                                      >
                                        REVISION
                                      </v-chip>
                                    </template>
                                    <span
                                      >Revision of
                                      {{ data.q_revise_barcode }}</span
                                    >
                                  </v-tooltip>
                                </template>
                              </div>
                              <!--  <v-chip
                                  v-if="data && data.quotation_revise && data.quotation_revise>0" 
                                  label
                                  small
                                  color="red"
                                  outlined
                                  class="text-white p-3 ml-2"
                                  >Revised</v-chip> -->
                            </div>
                          </div>
                        </template>

                        <template v-else-if="cols.field == 'amount'">
                          <div class="quotation-listing-amount">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Total Selling: </b>
                              <span class="font-weight-700">
                                {{ formatMoney(data?.total_selling) }}</span
                              >
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Total Cost Value : </b>
                              {{ formatMoney(data?.total_cost_value) }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'margin'">
                          <div class="quotation-listing-amount">
                            <div class="quotation-listing-amount">
                              <p
                                class="m-0 custom-nowrap-ellipsis font-weight-bold"
                                style="font-size: 15px"
                              >
                                <span class="font-weight-bold">
                                  {{ data?.total_margin }} %
                                  <!-- {{
                                  $accountingJS.formatMoney(data.total)
                                }} -->
                                </span>
                              </p>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'total'">
                          <div class="quotation-listing-amount">
                            <p
                              class="m-0 custom-nowrap-ellipsis font-weight-bold"
                              style="font-size: 15px"
                            >
                              <span class="font-weight-bold">
                                {{ formatMoney(data?.grand_total) }}
                                <!-- {{
                                  $accountingJS.formatMoney(data.total)
                                }} -->
                              </span>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.created_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="defaultColShow?.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no costing at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Costing Filter </template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-if="false"
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                    <DateRangePicker
                      placeholder="Data Range"
                      v-model="dates"
                      v-on:click:clear="clearFilter('dates')"
                      class="mr-2"
                    ></DateRangePicker>
                  </v-col>

                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <Datepicker
                        v-if="field == 'open_till'"
                        solo
                        flat
                        clearable
                        v-model.trim="listingSearch[field]"
                        label="Open Still"
                      ></Datepicker>

                      <v-text-field
                        v-else
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFinalLable(field, 'Costing')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="quotation/import-sample"
          api-url="quotation/import"
          title="Import Quotation"
          v-on:close="importDialog = false"
        ></ImportTemplate>

        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            disabled-auto-select
            :customerPersonDialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          >
          </CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Quotation Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import QuotationMixin from "@/core/lib/quotation/quotation.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import JwtService from "@/core/services/jwt.service";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import DateRangePicker from "@/view/components/DateRangePicker";
import Barcode from "@/view/pages/partials/Barcode.vue";

//import { map } from "lodash";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin, QuotationMixin],
  name: "costing-list",
  data() {
    return {
      userDetails: {},
      exportLoading: false,
      dates: [],
      pageModule: "costing-listing",
      routeAPI: "costing",
      routeName: "costing",
      routeDetailName: "costing.detail",
      importDialog: false,
      status: "all",
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Quotation(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
          title: "Export Quotation(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],

      rowData2: [],
    };
  },
  components: {
    PageTips,
    draggable,
    EntityDialog,
    CustomStatus,
    PageHeaderCount,
    // Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerPropertyDialog,
    CustomerPersonDialog,
    Datepicker,
    ImportTemplate,
    DateRangePicker,
    Barcode,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    getValidityDate(data) {
      if (data.added_at) {
        let date = moment(data.added_at, "YYYY-MM-DD").format("YYYY-MM-DD");
        let validity = moment(date, "YYYY-MM-DD")
          .add(data.validity_till, "days")
          .format("YYYY-MM-DD");
        return this.formatDate(validity);
      }
    },
    getColorType(data) {
      if (data == 1) {
        return "green darken-3";
      } else if (data == 2) {
        return "orange darken-3";
      } else if (data == 3) {
        return "red darken-3";
      }
    },
    getColorText(data) {
      if (data == 1) {
        return "New";
      } else if (data == 2) {
        return "Maintenance";
      } else if (data == 3) {
        return "General";
      }
    },
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkQuotation();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkQuotation();
    },

    checkQuotation() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("costing.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
  },
  mounted() {
    this.resetAll();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Costing",
      },
    ]);
    this.userDetails = JwtService.currentUser();
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
<style scoped>
.text-elipse {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
